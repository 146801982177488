import { CustomerData, ReducerStateType } from '../common/types';

export const FETCH_CUSTOMER_REQUESTED = 'FETCH_CUSTOMER_REQUESTED';
export function fetchCustomerRequested(): CustomerActionType {
  return { type: FETCH_CUSTOMER_REQUESTED };
}

export const FETCH_CUSTOMER_SUCCESSED = 'FETCH_CUSTOMER_SUCCESSED';
export function fetchCustomerSuccessed(customer): CustomerActionType {
  return { type: FETCH_CUSTOMER_SUCCESSED, payload: { customer } };
}

export const FETCH_CUSTOMER_FAILED = 'FETCH_CUSTOMER_FAILED';
export function fetchCustomerFailed(error): CustomerActionType {
  return { type: FETCH_CUSTOMER_FAILED, payload: { error } };
}

export interface CustomerStateType extends ReducerStateType {
  id?: string;
  data?: CustomerData;
}

export const customerInitialState: CustomerStateType = {
  isFetching: false,
  didInvalidate: false,
  data: null,
  error: null,
};

interface CustomerPayloadType {
  id?: string;
  customer?: CustomerData;
  error?: Error;
}

interface CustomerActionType {
  type: string;
  payload?: CustomerPayloadType;
}

function customerReducer(state, { type, payload }: CustomerActionType) {
  switch (type) {
    case FETCH_CUSTOMER_REQUESTED:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        error: false,
      };
    case FETCH_CUSTOMER_SUCCESSED:
      return {
        ...state,
        isFetching: false,
        id: payload.id,
        data: payload.customer,
      };
    case FETCH_CUSTOMER_FAILED:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload.error,
        didInvalidate: true,
      };
    default:
      throw new Error();
  }
}

export default customerReducer;
