import React from 'react';
import { useTranslation } from 'react-i18next';

function ArchiveBanner() {
  const { t } = useTranslation();

  return (
    <header style={{ width: '100%', backgroundColor: 'rgb(255, 204, 204)', padding: '2px', textAlign: 'center' }}>
      <div>{ t('archiveMessage') }</div>
    </header>
  )
}

export default ArchiveBanner;