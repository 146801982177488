declare global {
  interface Window {
    plausible: any;
  }
}

export function trackSensorAccordion({
  category,
  sensorId,
  sensorName,
  title,
}) {
  if (typeof window.plausible === 'undefined') {
    return;
  }
  window.plausible('accordion', {
    props: { category, sensorId, sensorName, title },
  });
}

export function trackFaqAccordion({ title, elementType, elementId, elementName }) {
  if (typeof window.plausible === 'undefined') {
    return;
  }
  window.plausible('faqAccordion', { props: { title, elementType, elementId, elementName } });
}

export function trackFAQ() {
  if (typeof window.plausible === 'undefined') {
    return;
  }
  window.plausible('clickedFAQ');
}

export function trackFeedback({ response, question, elementType, elementId, elementName }) {
  if (typeof window.plausible === 'undefined') {
    return;
  }
  window.plausible('submittedFeedback', {
    props: { response, question, elementType, elementId, elementName },
  });
}
