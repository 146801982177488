import React from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { SensorData, TaxonomyApiData } from 'common/types';
import DatachainBadge from 'components/DatachainBadge';
import { useTranslation } from 'react-i18next';

import {
  Typography,
  Link,
  Grid,
  Card,
  Chip,
  Divider,
} from 'libs/mui';

interface Props {
  datachainables: (SensorData)[];
  classes: any;
  dtprTaxonomy: TaxonomyApiData[];
  linkPrefix: string;
}

function DatachainSummaryGrid({
  datachainables,
  classes,
  dtprTaxonomy,
  linkPrefix,
}: Props) {
  
  const dtprIconFor = (dtprId: string) => {
    const element = dtprTaxonomy.find(
      (e) => e.id === dtprId
    );
    return element?.icon;
  };

  const dtprTitleFor = (dtprId: string) => {
    const element = dtprTaxonomy.find(
      (e) => e.id === dtprId
    );
    return element?.title;
  };
  
  const { t } = useTranslation();

  return (
    <Grid container spacing={3} className={classes.grid}>
      {datachainables.map(({ 
        id,
        tag,
        name,
        headline,
        datachainSummary
      }) => (
        <Grid item xs={12} md={6} key={id}>
          <Link
            href={`${linkPrefix}/${id}`}
            className={classes.gridItemLink}
            role="link"
            aria-label={t('pages.ariaLabels.technology', {
              sensor: name,
            })}
          >
            <Card 
              variant="outlined" 
              className={classes.gridCard}
            >
              <Typography variant="h2" className={classes.gridItemHeadline}>
                {headline || name}
                {tag && ( <Chip 
                  className={classes.tag}
                  label={tag}
                  size="small"
                  variant="outlined"
                  component="a"
                  href={`${linkPrefix}/${id}`}
                  clickable
                /> ) }
              </Typography>
              <div className={classes.gridIcons}>
                <Divider variant="fullWidth" />

                <div className={classes.badges}>
                  {datachainSummary.purpose && (
                    <DatachainBadge
                      title={dtprTitleFor(datachainSummary.purpose.dtpr_id)}
                      icon={dtprIconFor(datachainSummary.purpose.dtpr_id)}
                      category='purpose'
                    />                      
                  )}

                  {datachainSummary.tech && (
                    <DatachainBadge
                      title={dtprTitleFor(datachainSummary.tech.dtpr_id)}
                      icon={dtprIconFor(datachainSummary.tech.dtpr_id)}
                      category='tech'
                    />
                  )}

                  {datachainSummary.accountable && (
                    <DatachainBadge
                      title={datachainSummary.accountable.title || dtprTitleFor(datachainSummary.accountable.dtpr_id)}
                      icon={datachainSummary.accountable.icon || dtprIconFor(datachainSummary.accountable.dtpr_id)}
                      category='accountable'
                    />
                  )}
                </div>
              </div>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    grid: {
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    gridCard: {
      padding: theme.spacing(3),
      display: "flex",
      flexDirection: 'column',
      alignItems: 'center',
      height:"100%",
    },
    gridIcons: {
      display: 'block',
      width: '100%',
    },
    badges: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
    },
    tag: {
      marginLeft: theme.spacing(1),
    },
    gridItemLink: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
    gridItemHeadline: {
      fontWeight: 500,
      color: theme.custom.header,
      textAlign: 'center',
      marginBottom: theme.spacing(3),
      ...theme.custom.fonts.primary.l,
      flexGrow:5,
    },
  });

export default withStyles(styles)(DatachainSummaryGrid);
