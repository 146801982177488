/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { CustomerData } from 'common/types';

import { Typography } from 'libs/mui';
import LocaleSwitcher from 'components/LocaleSwitcher';
import { SUPPORTED_LANGUAGES } from 'common/constants';

interface Props {
  customer: CustomerData;
  classes: any;
}

function Footer({ customer, classes }: Props) {
  const { t } = useTranslation();

  return (
    <div className={classes.root} role="contentinfo">
      <div>
        <Typography
          dangerouslySetInnerHTML={{ __html: t('footer.text') }}
          className={classes.text}
        />
        {customer.privacyPolicyUrl?.length === 0 ? (
          <Typography
            dangerouslySetInnerHTML={{
              __html: t('footer.accountableText', {
                accountableName: customer.privacyPolicyResponsableOrganization,
              }),
            }}
            className={classes.text}
          />
        ) : (
          <Typography
            dangerouslySetInnerHTML={{
              __html: t('footer.accountablePrivacyPolicy', {
                accountableName: customer.privacyPolicyResponsableOrganization,
                privacyPolicyLink: customer.privacyPolicyUrl,
              }),
            }}
            className={classes.text}
          />
        )}
      </div>
      
      { SUPPORTED_LANGUAGES.length > 1 && (
        <div className={classes.localeSwitcher}>
          <LocaleSwitcher />
        </div>
      )}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      marginRight: 'auto',
      marginLeft: 'auto',
      paddingTop: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        maxWidth: theme.breakpoints.values.md,
      },
      padding: theme.spacing(2),
      display: 'flex',
      '@media print': {
        display: 'none',
      },
    },
    text: {
      color: theme.custom.footer.paragraph,
      fontSize: '0.75rem',
      letterSpacing: '0.5px',
      maxWidth: 900,
      marginTop: 5,
    },
    link: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      letterSpacing: '0.25px',
      marginBottom: theme.spacing(1.25),
      textDecorationLine: 'underline',
      color: theme.custom.link,
    },
    localeSwitcher: {
      marginLeft: theme.spacing(2),
    }
  });

export default withStyles(styles)(Footer);
