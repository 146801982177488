/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { markdownConverter } from 'libs/showdown';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

import {
  Typography,
  Link,
  PlaceIcon,
  EmailIcon,
  PhoneIcon,
  Divider
} from 'libs/mui';
import { PageData, TaxonomyApiData, FeedbackQuestion } from 'common/types';
import SensorTabs from 'components/Sensors/SensorTabs';
import DatachainSummaryGrid from 'components/DatachainSummaryGrid';
import SensorFeedback from 'components/Sensors/SensorFeedback';

interface Props {
  page: PageData;
  classes: any;
  dtprTaxonomy: TaxonomyApiData[];
  question: FeedbackQuestion;
  onResponse:(answer: string)=>void;
  progressText: string;
  progressTextAriaLabel: string;
  progressValue: number;
}

function PageView({
  classes,
  dtprTaxonomy,
  page,
  onResponse,
  progressText,
  progressTextAriaLabel,
  progressValue,
  question: feedbackQuestion,
}: Props) {
  const { t } = useTranslation();
  const screenReaderPhoneNumber = page.accountablePhone
    ?.replace('-', '')
    ?.split('')
    ?.join(',');

  useEffect(() => {
    document.title = `${page.name} - DTPR` 
  });

  const parsedIntroBody = markdownConverter.makeHtml(page.intro_body);

  return (
    <div className={classes.root}>
      <div className={classes.header} role="banner">
        {page.accountableLogo && (
          <img
            className={classes.image}
            src={page.accountableLogo}
            alt={t('pages.ariaLabels.logoAltText', {
              name: page.accountableName,
            })}
          />
        )}
        <Typography variant="h1" className={classes.name}>
          {page.name}
        </Typography>
        <Typography className={classes.subheader}>
          {t('pages.text', { name: page.accountableName })}
        </Typography>
        {page.address && (
          <div className={classes.contact}>
            <PlaceIcon
              aria-hidden
              className={classes.contactIcon}
              fontSize="small"
            />
            {page.address}
          </div>
        )}
        {page.accountableEmail && (
          <div className={classes.contact}>
            <Link
              aria-label={t('pages.ariaLabels.email', {
                email: page.accountableEmail,
              })}
              href={`mailto:${page.accountableEmail}`}
              role="link"
              target="blank"
              underline="always"
            >
              <EmailIcon
                aria-hidden
                className={classes.contactIcon}
                fontSize="small"
              />
              {page.accountableEmail}
            </Link>
          </div>
        )}
        {page.accountablePhone && (
          <div className={classes.contact}>
            <Link
              aria-label={t('pages.ariaLabels.phone', {
                phoneNumber: screenReaderPhoneNumber,
              })}
              
              className={classes.contact}
              href={`tel:${page.accountablePhone}`}
              role="link"
              underline="always"
            >
              <PhoneIcon
                aria-hidden
                className={classes.contactIcon}
                fontSize="small"
              />
              {page.accountablePhone}
            </Link>
          </div>
        )}
        
      </div>
      <div role="main">
        {!!page.intro_body && (
          
          <div className={classes.introBody}>
            <Typography
              dangerouslySetInnerHTML={{ __html: parsedIntroBody }}
              className={classes.paragraph}
            />
          </div>
        )}
        {page.feedback_active && ( 
        <SensorFeedback
          onClick={onResponse}
          question={feedbackQuestion}
          progressText={progressText}
          progressTextAriaLabel={progressTextAriaLabel}
          progressValue={progressValue}
          elementName={page.name}
          feedbackEmail={page.feedback_email}
          feedbackText={page.feedback_text}

        />
        )}
        <Divider variant="fullWidth" />
        <SensorTabs tabs={t('pages.tabs', { returnObjects: true })}>
          
          {page?.technologies?.length ? (
            <DatachainSummaryGrid
              datachainables={page?.technologies}
              dtprTaxonomy={dtprTaxonomy}
              linkPrefix={`/pages/${page.id}/technologies`}
            />
          ): (
            <div>
              <Typography className={classes.technologies}>
                {t('pages.noTechnologies')}
              </Typography>
            </div>
          )}
        </SensorTabs>
      </div>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 'auto',
      paddingTop: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        maxWidth: theme.breakpoints.values.md,
      },
    },
    header: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    image: {
      height: '48px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(),
    },
    name: {
      fontWeight: 700,
      color: theme.custom.header,
      ...theme.custom.fonts.primary.l,
    },
    subheader: {
      marginTop: theme.spacing(2),
    },
    contact: {
      alignItems: 'center',
      color: theme.custom.page.address,
      display: 'flex',
      justifyContent: 'center',
      ...theme.custom.fonts.secondary.xs,
    },
    contactIcon: {
      marginRight: theme.spacing(0.5),
    },
    technologies: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      position: 'relative',
    },
    taxonomy: {
      paddingTop: theme.spacing(),
    },
    sort: {
      alignItems: 'center',
      borderRadius: 0,
      color: theme.custom.page.sort.button,
      display: 'flex',
      padding: theme.spacing(0.5, 2),
      position: 'absolute',
      right: 0,
      top: 0,
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    sortText: {
      ...theme.custom.fonts.primary.xs,
      color: theme.custom.page.sort.button,
      fontWeight: 600,
      letterSpacing: '0.25px',
      textTransform: 'uppercase',

      marginRight: theme.spacing(0.5),
    },
    grid: {
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    gridCard: {
      padding: theme.spacing(3),
    },
    badges: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
    },
    gridItemLink: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
    gridItemHeadline: {
      fontWeight: 500,
      color: theme.custom.header,
      textAlign: 'center',
      marginBottom: theme.spacing(3),
      ...theme.custom.fonts.primary.l,
    },
    list: {
      padding: theme.spacing(0),
    },
    listItem: {
      border: `1px solid ${theme.custom.page.taxonomy.border}`,
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(1.5),
      padding: theme.spacing(1.5),
    },
    listItemText: {
      color: theme.custom.link,
      marginTop: theme.spacing(0),
      '&>*': {
        ...theme.custom.fonts.primary.s,
      },
      textDecoration: 'none',
    },
    listDescription: {
      color: theme.custom.page.taxonomy.description,
      marginTop: theme.spacing(1.5),
      ...theme.custom.fonts.secondary.m,
    },
    introBody: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    }
  });

export default withStyles(styles)(PageView);
