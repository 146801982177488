import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useQuery } from 'react-query';
import { queryTaxonomy } from 'libs/taxonomy';

import { getSensor } from 'sideEffects/api';
import sensorReducer, {
  sensorInitialState,
  fetchSensorRequested,
  fetchSensorSuccessed,
  fetchSensorFailed,
} from 'reducers/sensor';

import useReducerState from 'hooks/useReducerState';

import SensorTaxonomy from 'components/Sensors/SensorTaxonomy';
import LocaleSwitcher from 'components/LocaleSwitcher';

import { LinearProgress } from 'libs/mui';
import { prepareSensorTaxonomy } from 'presenters/sensor';
import {
  TaxonomyPropLabels,
} from 'common/types';
import NotFound from 'components/NotFound';

import { SUPPORTED_LANGUAGES } from 'common/constants';

function Sensor() {
  const { sensorId } = useParams();
  const { t } = useTranslation();
  const [sensor, sensorActions] = useReducerState(
    sensorReducer,
    sensorInitialState,
    fetchSensorRequested,
    fetchSensorSuccessed,
    fetchSensorFailed
  );

  const taxonomyPropLabels: TaxonomyPropLabels = t('taxonomy.labels', {
    returnObjects: true,
  });

  const { i18n } = useTranslation();
  const language = i18n.resolvedLanguage;
  const taxonomyQuery = useQuery(
    ['apiTaxonomy', { language }],
    () => queryTaxonomy(language),
    {
      // eslint-disable-next-line no-console
      onError: (e) => console.error(e),
      retry: false,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    async function fetchData() {
      // await redirectIfFirestoreId('sensors', sensorId);
      
      sensorActions.onRequest();
      getSensor(sensorId, language, sensorActions.onSuccess, sensorActions.onError);
    }

    fetchData();
  }, [sensorId]);

  const sensorTaxonomy = useMemo(() => {
    if (!sensor.data) return {};
    return prepareSensorTaxonomy({
      taxonomyApiData: taxonomyQuery.data,
      datachain: sensor.data?.datachain,
      taxonomyPropLabels,
    });
  }, [sensor.data, taxonomyPropLabels, taxonomyQuery.isSuccess]);


  if (taxonomyQuery.isError) {
    return <NotFound />;
  }

  if (sensor.didInvalidate) {
    return <NotFound message={sensor.error.message} code={sensor.error.code} />;
  }

  if (
    !sensor.data ||
    sensor.isFetching ||
    taxonomyQuery.isFetching
  ) {
    return <LinearProgress color="primary" />;
  }

  return (
    <>
      <SensorTaxonomy
        sensorTaxonomy={Object.values(sensorTaxonomy)}
        sensorId={sensorId}
        sensorName={sensor.name}
      />
      { SUPPORTED_LANGUAGES.length > 1 && (
        <LocaleSwitcher />
      )}
    </>
  );
}

export default Sensor;
