import { TaxonomyApiData } from 'common/types';

// eslint-disable-next-line no-undef
// interface FetchWithTimeoutOptions extends RequestInit {
//   timeout?: number;
// }

// async function fetchWithTimeout(
//   resource: string,
//   options: FetchWithTimeoutOptions = {}
// ) {
//   const { timeout = 8000 } = options;

//   const controller = new AbortController();
//   const id = setTimeout(() => controller.abort(), timeout);
//   const response = await fetch(resource, {
//     ...options,
//     signal: controller.signal,
//   });
//   clearTimeout(id);
//   return response;
// }

export async function queryTaxonomy(
  language: string
): Promise<TaxonomyApiData[]> {
  return new Promise((resolve, reject) => {
    fetch(`/localAPI/dtpr/${language}.json`)
      .then((response) => {
        if (!response.ok) {
          return reject(response);
        }
        return response.json();
      })
      .then((data) => {
        if (!data.length) {
          return reject(
            Error('API data is not the anticipated array of taxonomy items.')
          );
        }
        return resolve(data);
      })
      .catch((error) => reject(error));
  });
}
