import React from 'react';
import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { PageHeader } from 'common/types';

import { Typography, Button } from 'libs/mui';
import ArchiveBanner from './ArchiveBanner';

interface Props {
  pageHeader: PageHeader;
  classes: any;
}

function Pageheader({ pageHeader, classes }: Props) {
  return (
    <>
      <ArchiveBanner />
      <div className={classes.root}>
        {pageHeader?.active && (
          <div className={classes.container}>
            <Typography className={classes.headerText}>
              {pageHeader?.text}
            </Typography>
            {pageHeader?.has_button && (
              <div>
                <Link to={pageHeader?.button_url} target="_blank" rel="noopener noreferrer">
                  <Button variant="outlined" color="inherit" className={classes.button}>
                    {pageHeader?.button_text}
                  </Button>
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.custom.sensor.page.backgroundColor,
      color: theme.custom.sensor.page.paragraph,
      '@media print': {
        display: 'none',
      },
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2),
    },
    button: {
      whiteSpace: 'nowrap',
      color: theme.custom.sensor.page.paragraph,
    },
    headerText: {
      ...theme.custom.fonts.primary.m,
      marginRight: theme.spacing(2),
    }
  });

export default withStyles(styles)(Pageheader);
