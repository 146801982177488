/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from 'react';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from 'libs/mui';

const tabsStyles = (theme: Theme) =>
  createStyles({
    root: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    indicator: {
      backgroundColor: theme.custom.tab,
      height: '2px',
    },
  });

const StyledTabs = withStyles(tabsStyles)(Tabs);

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      ...theme.custom.fonts.primary.m,
      color: theme.custom.tab,
      fontWeight: 'bold',
      letterSpacing: '0.75px',
      minWidth: 125,
      textTransform: 'uppercase',
    },
  })
)(Tab);

interface TabPanelProps {
  children: ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}.`}
      aria-labelledby={`tab-${index}.`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}.`,
    'aria-controls': `tabpanel-${index}.`,
  };
}

interface Props {
  classes: any;
  children: ReactNode;
  tabs: string[];
  onChange?: (newValue: number) => void;
}

function SensorTabs({ classes, children, tabs, onChange }: Props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (onChange) {
      onChange(newValue);
    }

    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <StyledTabs value={value} onChange={handleChange}>
        {tabs.map((tab, i) => (
          <StyledTab key={tab} label={tab} {...a11yProps(i)} />
        ))}
      </StyledTabs>
      {React.Children.map(children, (child, i) => (
        <TabPanel key={`tab-${i}`} value={value} index={i}>
          {child}
        </TabPanel>
      ))}
    </div>
  );
}

SensorTabs.defaultProps = {
  onChange: undefined,
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(),
    },
  });

export default withStyles(styles)(SensorTabs);
