import { common } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import defaultTheme from './themes/defaultTheme';
import bostonTheme from './themes/bostonTheme';
import dcTheme from './themes/dcTheme';
import innisfilTheme from './themes/innisfilTheme';
import sopaTheme from './themes/sopaTheme';
import underlineTheme from './themes/underlineTheme';
import charlotteTheme from './themes/charlotteTheme';
import michiganCentralTheme from './themes/michiganCentralTheme';

const customerId = process.env.REACT_APP_HP_CUSTOMER_ID;

const themes = {
  '73f155d7-74fa-4604-86c3-e2012cadf064': bostonTheme,
  '176ed412-72c7-4929-9665-889e4ed67863': dcTheme,
  '334ed986-98e7-4031-88b6-899c4971c71a': innisfilTheme,
  'f32f3c2e-4375-4a92-a3a7-32f265cb9e63': sopaTheme,
  '78dfd65b-a82e-440d-8adb-a53f7a3e0ef5': underlineTheme,
  '6bc213f5-671a-4a11-92d0-1fbea5773cd6': charlotteTheme,
  '28ee6ff5-6e61-41fe-b90c-c14962908cb9': michiganCentralTheme

};

let customTheme = defaultTheme;

if (themes.hasOwnProperty(customerId)) {
  customTheme = themes[customerId];
}

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: customTheme.header,
      // dark: will be calculated from palette.primary.main,
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: common.white,
    },
    text: {
      primary: customTheme.sensor.paragraph,
    },
  },
  typography: {
    fontFamily: customTheme.fonts.primary.m.fontFamily,
  },
  custom: customTheme,
});

export const FONT_FAMILY = customTheme.fonts.primary.m.fontFamily;

export default theme;
