import { useEffect } from 'react';
import { getDefaultPage } from 'sideEffects/api';

function Redirect() {
  useEffect(() => {
    const redirectToDefaultPlace = async () => {
      const pageId = await getDefaultPage();
      window.location.href = `pages/${pageId}`;
    };

    redirectToDefaultPlace();
  }, []);
  return null;
}

export default Redirect;
