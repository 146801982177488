/* eslint-disable import/no-extraneous-dependencies */
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import {
  PageData,
  SensorData,
  CustomerData,
} from '../common/types';

function mapSensor(s: any) {
  const sensor = {
    ...s,
    FAQ: s.questions,
    datachain: groupBy(
      sortBy(s.datachain, ({ priority }) => {
        const number = Number.parseInt(priority, 10);
        if (Number.isNaN(number)) return null;
        return number;
      }),
      ({ category }) => category
    ),
  };

  delete sensor.questions;

  return sensor;
}

export async function getCustomer(
  onSuccess: (arg0: { id: any }) => void,
  onError: (arg0: { code: number; status: string; message: string }) => void
){

  try{
    const response=await fetch(`/localAPI/${process.env.REACT_APP_HP_CUSTOMER_ID}/${process.env.REACT_APP_HP_CUSTOMER_ID}.json`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data=await response.json();
    const customerData=data as CustomerData
    if (!customerData) {
      onError({
        code: 404,
        status: 'Not Found',
        message: 'Customer not found',
      });
      return;
    }
    onSuccess(customerData);
  } catch (e) {
    onError(e);
  }
};

export async function getPage(
  pageId: string,
  locale:string,
  onSuccess: (arg0: { id: any }) => void,
  onError: (arg0: { code: number; status: string; message: string }) => void
){
  try {
    const response = await fetch(`/localAPI/${process.env.REACT_APP_HP_CUSTOMER_ID}/pages/${pageId}/${locale}.json`);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const pageData = data as PageData;

    if (!pageData) {
      onError({
        code: 404,
        status: 'Not Found',
        message: 'Page not found',
      });
      return;
    }
    onSuccess(pageData);
  } catch (e) {
    onError({
      code: e.code || 500,
      status: e.status || 'Error',
      message: e.message || 'An error occurred',
    });
  }
}

export async function getSensor(
  technologyId:string,
  locale:string,
  onSuccess: (param?: SensorData) => void,
  onError: (e: any) => void
){
  try{
    const response=await fetch(`/localAPI/${process.env.REACT_APP_HP_CUSTOMER_ID}/technologies/${technologyId}/${locale}.json`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data=await response.json();
    const sensor = mapSensor(data) as SensorData;

    if (!sensor) {
      onError({
        code: 404,
        status: 'Not Found',
        message: 'Sensor not found',
      });
      return;
    }

    onSuccess(sensor);
  } catch (e) {
    onError(e);
  }
};



export async function getSensorsByPage(
  pageId:string,
  locale:string,
  onSuccess: (param: { [sensorId: string]: SensorData }) => void,
  onError: (e: any) => void

){
  try{
    const response=await fetch(`/localAPI/${process.env.REACT_APP_HP_CUSTOMER_ID}/pages/${pageId}/${locale}.json`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data=await response.json();

    const sensors: { [sensorId: string]: SensorData } = {};

    data.technologies.forEach((tech)=>{
      sensors[tech.id]=mapSensor(tech)

    });
    onSuccess(sensors);
  } catch (e) {
    onError(e);
  }
}



// Get default page to which to redirect
export async function getDefaultPage() {
  try {
    const url = `/localAPI/${process.env.REACT_APP_HP_CUSTOMER_ID}/${process.env.REACT_APP_HP_CUSTOMER_ID}.json`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const contentType = response.headers.get('content-type');
    if (!contentType || !contentType.includes('application/json')) {
      throw new Error(`Expected JSON, but received ${contentType}`);
    }

    const data = await response.json();

    return data.defaultPage;
  } catch (error) {
    throw new Error(error.message || 'An error occurred');
  }
}

// function trackFirestoreVisit() {
//   if (typeof window.plausible === 'undefined') {
//     return;
//   }
//   window.plausible('pageview');
// }

// export async function redirectIfFirestoreId(table: string, id: string) {
//   const { data } = await apiAxios.get(`${table}/${id}/firestore_check`);

//   if (data?.type === 'firestore') {
//     trackFirestoreVisit();
//     window.location.href = `/${table}/${data.id}`;
//   }
// }



export async function getDefaultPageAction(
  onSuccess: (arg0: { id: any }) => void,
  onError: (arg0: { code: number; status: string; message: string }) => void
) {
  try {

    const response=await fetch(`/localAPI/${process.env.REACT_APP_HP_CUSTOMER_ID}/${process.env.REACT_APP_HP_CUSTOMER_ID}.json`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data=await response.json();
    

    const pageData = data as PageData;

    if (!pageData) {
      onError({
        code: 404,
        status: 'Not Found',
        message: 'Page not found',
      });
      return;
    }
    onSuccess(pageData);
  } catch (e) {
    onError(e);
  }
}
