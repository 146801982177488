import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

import { Button } from 'libs/mui';
import { feedbackEmojis } from 'common/constants';
import FeedbackEmoji from '../Svgs/FeedbackEmoji';

interface Props {
  onClick: (name: string) => void;
  classes: any;
}

function FeedbackEmojis({ classes, onClick }: Props) {
  const { t } = useTranslation();

  const emojisList = [
    {
      name: feedbackEmojis.VERY_SAD,
      color: '#E53319',
      bgColor: '#FCDBD4',
      ariaLabel: t('feedback.emjoiAriaLabels.verySad'),
    },
    {
      name: feedbackEmojis.SAD,
      color: '#D6752A',
      bgColor: '#FDE8D7',
      ariaLabel: t('feedback.emjoiAriaLabels.sad'),
    },
    {
      name: feedbackEmojis.NEUTRAL,
      color: '#BB9F5D',
      bgColor: '#FFF5D7',
      ariaLabel: t('feedback.emjoiAriaLabels.neutral'),
    },
    {
      name: feedbackEmojis.HAPPY,
      color: '#74994D',
      bgColor: '#E6F6D4',
      ariaLabel: t('feedback.emjoiAriaLabels.happy'),
    },
    {
      name: feedbackEmojis.VERY_HAPPY,
      color: '#16A04D',
      bgColor: '#CDF1E0',
      ariaLabel: t('feedback.emjoiAriaLabels.veryHappy'),
    },
  ];

  return (
    <div className={classes.container}>
      {emojisList.map(({ name, color, bgColor, ariaLabel }) => (
        <Button
          aria-label={ariaLabel}
          variant="outlined"
          disableRipple
          className={classes.button}
          key={name}
          style={{
            backgroundColor: bgColor,
          }}
          onClick={() => onClick(name)}
        >
          <FeedbackEmoji emoji={name} color={color} />
        </Button>
      ))}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      border: '1px solid rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
      '&:focus': {
        border: '1px solid rgba(0, 0, 0, 0.6)',
      },
      margin: theme.spacing(0.5),
      minHeight: '41px',
      minWidth: '41px',
      width: '100%',
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  });

export default withStyles(styles)(FeedbackEmojis);
