/* eslint-disable react/require-default-props */
import React from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

import { Button, Typography } from 'libs/mui';

interface Props {
  code?: string;
  message?: string;
  classes: any;
}

function NotFound({
  classes,
  code = 'Not Found',
  message = 'An error has occured.',
}: Props) {
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.errorCode} variant="h2">
        {code}
      </Typography>
      <Typography variant="h1" className={classes.errorMessage}>
        {message}
      </Typography>
      <Button href="https://dtpr.io/" className={classes.button}>
        Go to the DTPR homepage
      </Button>
      <Button onClick={reloadPage} className={classes.button}>
        Reload this page
      </Button>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      margin: 'auto',
      paddingTop: theme.spacing(2),
      height: '85vh',
      [theme.breakpoints.up('md')]: {
        maxWidth: theme.breakpoints.values.md,
      },
    },
    errorCode: {
      padding: theme.spacing(2),
      textAlign: 'center',
      fontWeight: 'bold',
      color: '#828282',
    },
    errorMessage: {
      fontSize: '1.5rem',
      color: '#828282',
    },
    button: {
      marginTop: theme.spacing(2),
      backgroundColor: theme.palette.secondary.dark,
      '&:hover': {
        backgroundColor: 'grey',
      },
    },
  });

export default withStyles(styles)(NotFound);
