import React from 'react';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

import FeedbackQuestion from 'components/Feedback/FeedbackQuestion';
import { Typography, LinearProgress } from 'libs/mui';
import {
  FeedbackQuestion as FeedbackQuestionType
} from 'common/types';

interface Props {
  onClick: (answer: string) => void;
  classes: any;
  question: FeedbackQuestionType;
  progressText: string;
  progressTextAriaLabel: string;
  progressValue: number;
  elementName: string;
  feedbackEmail: string;
  feedbackText: string;
}

function Feedback({
  classes,
  onClick,
  question,
  progressText,
  progressTextAriaLabel,
  progressValue,
  elementName,
  feedbackEmail,
  feedbackText
}: Props) {
  return (
    <div className={classes.container}>
      <LinearProgress
        aria-hidden
        className={classes.linearProgress}
        variant="determinate"
        value={progressValue}
        classes={{ barColorPrimary: classes.barColorPrimary }}
      />
      <FeedbackQuestion
        onClick={onClick}
        text={question.text}
        type={question.type}
        progressTextAriaLabel={progressTextAriaLabel}
        elementName={elementName}
        feedbackEmail={feedbackEmail}
        feedbackText={feedbackText}
      />
      <Typography className={classes.progressText} aria-hidden>
        {progressText}
      </Typography>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.custom.sensor.feedback.background,
      borderTop: `1px solid ${theme.custom.sensor.feedback.divider}`,
      padding: theme.spacing(2),
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
      marginTop: theme.spacing(4),
      position: 'relative',
    },
    title: {
      fontWeight: 700,
      ...theme.custom.fonts.primary.m,
      color: theme.custom.sensor.feedback.header,
    },
    progressText: {
      textAlign: 'center',
      marginTop: theme.spacing(3),
      ...theme.custom.fonts.secondary.s,
      color: theme.custom.sensor.feedback.counter,
    },
    linearProgress: {
      backgroundColor: 'transparent',
      bottom: 0,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
    },
    barColorPrimary: {
      backgroundColor: theme.custom.sensor.feedback.progressBar,
    },
  });

export default withStyles(styles)(Feedback);
