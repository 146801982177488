export enum taxonomyProps {
  ACCOUNTABLE = 'accountable',
  PURPOSE = 'purpose',
  TECH = 'tech',
  DATA = 'data',
  PROCESS = 'process',
  ACCESS = 'access',
  STORAGE = 'storage',
  SYSTEMS = 'systems',
}

export enum feedbackEmojis {
  VERY_SAD = 'verySad',
  SAD = 'sad',
  NEUTRAL = 'neutral',
  HAPPY = 'happy',
  VERY_HAPPY = 'veryHappy',
}

export enum feedbackQuestionTypes {
  COMMENT = 'comment',
  EMOJI = 'emoji',
  THANKS = 'thanks',
}

export const PLACEHOLDERS = {
  [taxonomyProps.ACCOUNTABLE]: 'placeholders/accountable',
  [taxonomyProps.ACCESS]: 'placeholders/access',
  [taxonomyProps.PROCESS]: 'placeholders/process',
  [taxonomyProps.DATA]: 'placeholders/data',
  [taxonomyProps.PURPOSE]: 'placeholders/purpose',
  [taxonomyProps.STORAGE]: 'placeholders/storage',
  [taxonomyProps.TECH]: 'placeholders/tech',
};

export const HELPFULPLACES_WEBSITE = 'https://www.helpfulplaces.com/';
export const SUPPORTED_LANGUAGES = (process.env.REACT_APP_LOCALES && process.env.REACT_APP_LOCALES.split(',')) || ['en'];
