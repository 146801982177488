import useReducerState from 'hooks/useReducerState';
import React, { ReactNode, /* useCallback */} from 'react';

import pageReducer, {
  fetchPageFailed,
  fetchPageRequested,
  fetchPageSuccessed,
  pageInitialState,
  PageStateType,
} from 'reducers/page';
import { getPage, getDefaultPageAction } from 'sideEffects/api';

export const PageContext = React.createContext([pageInitialState, {}] as [
  PageStateType,
  { [name: string]: any }
]);

export function PageProvider({ children }: { children: ReactNode }) {
  const [page, pageActions] = useReducerState(
    pageReducer,
    pageInitialState,
    fetchPageRequested,
    fetchPageSuccessed,
    fetchPageFailed
  );

  const onRequest = (pageId: string, language: string) => {
    async function fetchData() {
      // await redirectIfFirestoreId('pages', pageId);
      getPage(pageId, language, pageActions.onSuccess, pageActions.onError);
    }

    if (pageId) {
      fetchData();
    } else {
      getDefaultPageAction(pageActions.onSuccess, pageActions.onError);
    }
  }

  const value: [PageStateType, { onRequest; onSuccess; onError }] = [
    page as PageStateType,
    { ...pageActions, onRequest },
  ];

  return (
    <PageContext.Provider value={value}>{children}</PageContext.Provider>
  );
}
