import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useLocation } from 'react-router-dom';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { SUPPORTED_LANGUAGES } from 'common/constants';
import translationEN from './en.json';
import translationFR from './fr.json';
import translationES from './es.json';
// import translationPT from './pt.json';

declare global {
  interface Window {
    plausible: any;
  }
}

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  es: {
    translation: translationES,
  },
  // pt: {
  //   translation: translationPT,
  // },
};

const options = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
  lookupQuerystring: 'lng',
};

const supportedLngs = SUPPORTED_LANGUAGES;
const initializeLocalization = () =>
  i18next
    // // detect user language
    // // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // // init i18next
    // // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      // lng: 'fr', // <--- turn off for detection to work
      resources,
      detection: options,
      fallbackLng: supportedLngs[0],
      supportedLngs,
    });

initializeLocalization();

function setLangOnPlausible() {
  if (typeof window.plausible === 'undefined') { return; }
  const { lang } = document.documentElement;
  window.plausible('pageview', { props: { lang } });
}

i18next.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
  setLangOnPlausible();
});

function Localization() {
  const location = useLocation();
  
  useEffect(() => {
    setLangOnPlausible();
  }, [location]);
  
  return <></>;
}

export default Localization;
