import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Redirect from 'components/Redirect';

import Page from './Page';
import Sensor from '../Sensors/Sensor';

function Pages() {
  return (
    <Routes>
      <Route path="/:pageId" element={<Page />} />
      <Route path="/:pageId/technologies/:sensorId" element={<Sensor />} />
      <Route path="/:pageId/systems/:sensorId" element={<Sensor />} />
      <Route path="*" element={<Redirect />} />
    </Routes>
  );
}

export default Pages;
