import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Typography } from 'libs/mui';
import { markdownConverter } from 'libs/showdown';

interface Props {
  classes: any;
  elementName: string;
  feedbackEmail: string;
  feedbackText: string;
}

function FeedbackThanks({ classes, elementName, feedbackEmail, feedbackText }: Props) {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      {feedbackText && (
        <Typography
          dangerouslySetInnerHTML={{
            __html: markdownConverter.makeHtml(feedbackText),
          }}
          className={classes.text}
          aria-live="polite"
        />
      )}
      <Typography
        dangerouslySetInnerHTML={{
          __html: t('feedback.furtherQuestions', {
            feedbackEmail,
            location: window.location.href,
            elementName
          }),
        }}
        className={classes.text}
        aria-live="polite"
      />
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      minHeight: '100px',
      width: '100%',
    },
    text: {
      marginTop: theme.spacing(1),
      color: theme.custom.sensor.feedback.paragraph,
      ...theme.custom.fonts.secondary.m,
    },
    link: {
      color: theme.custom.link,
    },
  });

export default withStyles(styles)(FeedbackThanks);
