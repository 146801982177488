/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { trackFAQ } from 'libs/analytics';
import { Typography, Divider } from 'libs/mui';

import {
  FAQ,
  FeedbackQuestion,
  SensorTaxonomyPropValuesDetails,
  SensorData,
  TaxonomyApiData
} from 'common/types';
import { PLACEHOLDERS } from 'common/constants';

import SensorFeedback from './SensorFeedback';
import SensorBadge from './SensorBadge';
import SensorDetails from './SensorDetails';
import SensorTaxonomy from './SensorTaxonomy';
import SensorTabs from './SensorTabs';
import SensorPage from './SensorPage';
import SensorFAQAccordion from './SensorFAQAccordion';
import DatachainSummaryGrid from '../DatachainSummaryGrid';

interface Props {
  sensor: SensorData;
  sensorId: SensorData['id'];
  pageId: string;
  sensorTaxonomy: {
    [name: string]: SensorTaxonomyPropValuesDetails;
  };
  classes: any;
  faq: FAQ[];
  onResponse: (answer: string) => void;
  question: FeedbackQuestion;
  progressText: string;
  progressTextAriaLabel: string;
  progressValue: number;
  dtprTaxonomy: TaxonomyApiData[];
}

function SensorView({
  classes,
  sensor,
  sensorId,
  pageId,
  sensorTaxonomy,
  faq,
  onResponse,
  question: feedbackQuestion,
  progressText,
  progressTextAriaLabel,
  progressValue,
  dtprTaxonomy
}: Props) {
  const handleTabChange = (tabIndex: number) => {
    if (tabIndex === 1) {
      trackFAQ();
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `${sensor.headline} - DTPR`
  });

  const sensorPage = useMemo(() => {
    if (!sensor.pages) return null;
    return sensor.pages.find((p) => p.id === pageId);
  }, [sensor.pages, pageId]);

  return (
    <div className={classes.root} role="main">
      <div className={classes.header}>
        {!!sensor.headline && (
          <Typography
            variant="h1"
            align="center"
            className={classes.headerText}
          >
            {sensor.headline}
          </Typography>
        )}
      </div>
      <Divider variant="fullWidth" />
      <div
        className={classes.summaryWrapper}
        aria-label={t('sensors.view.summaryAriaLabel')}
      >
        <SensorBadge
          option={sensorTaxonomy?.purpose?.options[0]}
          imgSrc={sensorTaxonomy?.purpose?.options[0].icon}
          placeholder={PLACEHOLDERS.purpose}
        />
        <SensorBadge
          option={sensorTaxonomy?.tech?.options[0]}
          imgSrc={sensorTaxonomy?.tech?.options[0].icon}
          placeholder={PLACEHOLDERS.tech}
        />
        <SensorBadge
          option={sensorTaxonomy?.accountable?.options[0]}
          imgSrc={sensorTaxonomy?.accountable?.options[0].icon}
          placeholder={PLACEHOLDERS.accountable}
        />
      </div>
      <Divider variant="fullWidth" />

      <SensorTabs
        tabs={t('sensors.view.tabs', { returnObjects: true })}
        onChange={handleTabChange}
      >
        <div className={classes.details}>
          <SensorDetails description={sensor.intro_body} />
          {sensor.feedback_active && (
            <SensorFeedback
              onClick={onResponse}
              question={feedbackQuestion}
              progressText={progressText}
              progressTextAriaLabel={progressTextAriaLabel}
              progressValue={progressValue}
              elementName={sensor.name}
              feedbackEmail={sensor.feedback_email}
              feedbackText={sensor.feedback_text}
            />
          ) }
          <SensorTaxonomy
            sensorTaxonomy={Object.values(sensorTaxonomy)}
            sensorId={sensorId}
            sensorName={sensor.name}
          />

          {!!sensor?.childTechnologies?.length && (
            <div className={classes.childTechnologies}>
              <DatachainSummaryGrid
                datachainables={sensor?.childTechnologies}
                dtprTaxonomy={dtprTaxonomy}
                linkPrefix={sensorPage ? `/pages/${sensorPage.id}/technologies` : `/technologies`}
              />
            </div>
          )}
        </div>
        <div className={classes.faq}>
          {faq?.length ? (
            faq.map(({ question, response }, i) => (
              <SensorFAQAccordion
                key={`question-${i}`}
                title={question}
                elementId={sensorId}
                elementName={sensor.name}
                elementType="sensor"
              >
                {response}
              </SensorFAQAccordion>
            ))
          ) : (
            <Typography>{t('sensors.view.faq.text')}</Typography>
          )}
        </div>
      </SensorTabs>
      
      {!!sensorPage && (
        <SensorPage page={sensorPage} />
      )}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 'auto',
      paddingTop: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        maxWidth: theme.breakpoints.values.md,
      },
    },
    header: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    headerText: {
      wordBreak: 'break-word',
      fontWeight: 'bold',
      color: theme.custom.header,
      ...theme.custom.fonts.primary.l,
    },
    summaryWrapper: {
      display: 'flex',
      padding: theme.spacing(2),
    },
    sensorImage: {
      width: '100%',
      maxWidth: '100%',
      maxHeight: '300px',
      margin: 'auto',
      marginBottom: theme.spacing(2),
    },
    details: {
      paddingTop: theme.spacing(1),
    },
    faq: {
      padding: theme.spacing(3),
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
    childTechnologies: {
      marginBottom: theme.spacing(5),
    },
  });

export default withStyles(styles)(SensorView);
