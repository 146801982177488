const sopaTheme = {
    link: '#038738',
    tab: '#038738',
    header: '#000000',
    sensor: {
      badge: '#000000',
      paragraph: '#000000',
      taxonomy: {
        value: '#000000',
        prop: '#4C4C4C',
        details: '#000000',
      },
      feedback: {
        progressBar: '#03913C',
        header: '#000000',
        paragraph: '#4C4C4C',
        background: '#F9F9F9',
        divider: '#B4B4B4',
        counter: '#4C4C4C',
      },
      page: {
        backgroundColor: '#03913C',
        paragraph: 'white',
        divider: 'white',
        link: 'white',
      },
      faq: {
        question: '#000000',
        response: '#000000',
      },
    },
    page: {
      address: '#4C4C4C',
      taxonomy: {
        title: '#4C4C4C',
        description: '#4C4C4C',
        border: '#EEEEEE',
      },
      sort: {
        header: '#4C4C4C',
        selected: '#787878',
        option: '#828282',
        button: '#828282',
      },
    },
    footer: {
      paragraph: '#4C4C4C',
    },
    fonts: {
      primary: {
        l: {
          fontSize: '1.5rem',
          fontFamily: '"Arial", sans-serif',
          letterSpacing: '0.15px',
          lineHeight: '1.5rem',
        },
        m: {
          fontSize: '1rem',
          fontFamily: '"Arial", sans-serif',
          letterSpacing: '0.15px',
          lineHeight: '1.5rem',
        },
        s: {
          fontSize: '0.875rem',
          fontFamily: '"Arial", sans-serif',
          letterSpacing: '0.15px',
          lineHeight: '1.25rem',
        },
        xs: {
          fontSize: '0.75rem',
          fontFamily: '"Arial", sans-serif',
          letterSpacing: '0.15px',
          lineHeight: '1rem',
        },
      },
      secondary: {
        m: {
          fontSize: '1rem',
          fontFamily: '"Arial", sans-serif',
          letterSpacing: '0.15px',
          lineHeight: '1.5rem',
        },
        s: {
          fontSize: '0.875rem',
          fontFamily: '"Arial", sans-serif',
          letterSpacing: '0.15px',
          lineHeight: '1.25rem',
        },
        xs: {
          fontSize: '0.75rem',
          fontFamily: '"Arial", sans-serif',
          letterSpacing: '0.15px',
          lineHeight: '1rem',
        },
      },
    },
  };
  
  export default sopaTheme;
  