import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { PageData } from 'common/types';
import { Divider, Typography, ArrowForwardIcon } from 'libs/mui';

interface Props {
  classes: any;
  page: PageData;
}

function SensorPage({ classes, page }: Props) {
  const { t } = useTranslation();

  return (
    <div className={classes.root} role="complementary">
      <Typography className={classes.name} variant="h2">
        {page?.name}
      </Typography>
      <Typography variant="srOnly">
        {t('pages.ariaLabels.address', { address: page.address })}
      </Typography>
      <Typography className={classes.address} aria-hidden>
        {page?.address}
      </Typography>
      <Divider className={classes.divider} variant="inset" />
      <Typography className={classes.paragraph}>
        {t('sensors.view.page.location')}
      </Typography>
      <Link
        to={`/pages/${page?.id}`}
        className={classes.link}
        role="link"
        aria-label={t('sensors.view.page.seeAllAriaLabel')}
      >
        {t('sensors.view.page.seeAll')}
        <ArrowForwardIcon className={classes.linkIcon} />
      </Link>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.custom.sensor.page.backgroundColor,
      color: theme.custom.sensor.page.paragraph,
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(4),
    },
    address: {
      ...theme.custom.fonts.secondary.s,
      marginTop: theme.spacing(),
    },
    divider: {
      height: '2px',
      backgroundColor: theme.custom.sensor.page.divider,
      marginBottom: theme.spacing(3),
      marginLeft: 0,
      marginTop: theme.spacing(),
      width: theme.spacing(5),
    },
    link: {
      alignItems: 'center',
      color: theme.custom.sensor.page.link,
      display: 'flex',
      fontSize: '1rem',
      fontWeight: 700,
      letterSpacing: '0.25px',
      marginTop: theme.spacing(),
      textDecoration: 'none',
      textTransform: 'uppercase',
    },
    linkIcon: {
      color: theme.custom.sensor.page.link,
      fontWeight: 700,
      marginLeft: theme.spacing(),
    },
    name: {
      fontWeight: 500,
      ...theme.custom.fonts.primary.l,
    },
    paragraph: {
      ...theme.custom.fonts.secondary.m,
    },
  });

export default withStyles(styles)(SensorPage);
