import React from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { getIconPlaceholder, showPlaceholderOnImgError } from 'common/helpers';

interface Props {
  option: any;
  classes: any;
  placeholder: string;
  imgSrc?: string;
}

function SensorBadge({ option, classes, placeholder, imgSrc }: Props) {
  if (!option) return <></>;

  const placeholderIcon = getIconPlaceholder(placeholder);
  return (
    <div className={classes.root}>
      <img
        className={classes.image}
        src={imgSrc || placeholderIcon}
        alt={`${option.category}:`}
        onError={showPlaceholderOnImgError(placeholderIcon)}
      />
      <Typography className={classes.name}>{option.title}</Typography>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      textAlign: 'center',
    },
    image: {
      height: '48px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: theme.spacing(),
    },
    name: {
      ...theme.custom.fonts.secondary.xs,
      color: theme.custom.sensor.badge,
      fontWeight: 500,
    },
  });

SensorBadge.defaultProps = {
  imgSrc: '',
};

export default withStyles(styles)(SensorBadge);
