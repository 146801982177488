/* eslint-disable camelcase */
import React from 'react';

import { PLACEHOLDERS } from 'common/constants';
import { SensorData, SensorTaxonomyPropValuesDetails } from 'common/types';
import { createStyles, Theme, withStyles } from '@material-ui/core';
import SensorAccordion from './SensorAccordion';

interface Props {
  sensorTaxonomy?: SensorTaxonomyPropValuesDetails[];
  classes: any;
  sensorId: SensorData['id'];
  sensorName: SensorData['name'];
}

function SensorTaxonomy({
  classes,
  sensorTaxonomy,
  sensorId,
  sensorName,
}: Props) {
  if (!Object.keys(sensorTaxonomy).length) <></>;

  return (
    <div className={classes.root}>
      {sensorTaxonomy.map(({ taxonomyProp, label, options }) =>
        options.map(({ title, description, icon, additional_description }) => (
          <SensorAccordion
            key={title}
            icon={icon}
            sensorId={sensorId}
            sensorName={sensorName}
            placeholder={`/images/${PLACEHOLDERS[taxonomyProp]}.svg`}
            title={title}
            label={label}
            body={description}
            additionalInfo={additional_description}
          />
        ))
      )}
    </div>
  );
}

SensorTaxonomy.defaultProps = {
  sensorTaxonomy: undefined,
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  });

export default withStyles(styles)(SensorTaxonomy);
