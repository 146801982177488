import React from 'react';
import { markdownConverter } from 'libs/showdown';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

interface Props {
  description?: string;
  classes: any;
}

function SensorDetails({ description, classes }: Props) {
  if (!description) return <></>;

  const parsedDescription = markdownConverter.makeHtml(description);

  return (
    <div className={classes.root}>
      {!!description && (
        <div>
          <Typography
            dangerouslySetInnerHTML={{ __html: parsedDescription }}
            className={classes.paragraph}
          />
        </div>
      )}
    </div>
  );
}

SensorDetails.defaultProps = {
  description: ''
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      display: 'grid',
      rowGap: theme.spacing(2),
      padding: theme.spacing(2),
    },
    system: {
      marginTop: theme.spacing(),
      paddingLeft: theme.spacing(),
    },
    systemDescription: {
      paddingLeft: theme.spacing(),
    },
    paragraph: {
      ...theme.custom.fonts.secondary.m,
      color: theme.custom.sensor.paragraph,
    },
    paragraphHeading: {
      ...theme.custom.fonts.secondary.m,
      color: theme.custom.sensor.paragraph,
      fontWeight: 700,
    },
  });

export default withStyles(styles)(SensorDetails);
