import React from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

interface Props {
  title: string;
  icon: string;
  category: string;
  classes: any;
}

function DatachainBadge({ title, icon, category, classes }: Props) {
  return (
    <div className={classes.root}>
        <img
          className={classes.image}
          src={icon}
          alt={`${category}:`}
        />
      <Typography className={classes.name}>{title}</Typography>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: { 
      textAlign: 'center',
      marginLeft: '20px',
      marginRight: '20px',
      width: '88px'
    },
    image: {
      height: '48px',
      width: '48px',
      objectFit: 'contain',
    },
    name: {
      ...theme.custom.fonts.secondary.xs,
      color: theme.custom.sensor.badge,
      fontWeight: 500,
    },
  });

export default withStyles(styles)(DatachainBadge);
