/* eslint-disable import/prefer-default-export */
import { taxonomyProps } from 'common/constants';
import {
  TaxonomyApiData,
  TaxonomyDetails,
  TaxonomyPropLabels,
} from 'common/types';

// Use API as source of truth for taxonomy.
// As a fallback for legacy firestore data and custom 'accountable' data chain items: use firebase data.

function mergeDatachainItems(datachainItems, taxonomyApiData) {
  // Map through firebase datachain data, if 'dtpr_id' exists use data from the API otherwise use firebase data.
  return datachainItems?.map((firestoreDatachainItem: TaxonomyDetails) => {
    let mergedData = { ...firestoreDatachainItem };

    if (firestoreDatachainItem.hasOwnProperty('id')) {
      const matchedApiDatachainItem = taxonomyApiData?.find(
        (apiDatachainItem) =>
          apiDatachainItem.id === firestoreDatachainItem.dtpr_id
      );
      if (matchedApiDatachainItem) {
        mergedData = { ...mergedData, ...matchedApiDatachainItem };
      }
    }
    return mergedData;
  });
}

export function mergeDatachainWithTaxonomy(
  datachain: {
    [name: string]: TaxonomyDetails[];
  },
  taxonomyApiData: TaxonomyApiData[]
) {
  if (!datachain) return {};

  return Object.values(taxonomyProps).reduce((res, taxonomyProp) => {
    const mergedDatachainItems =
      datachain[taxonomyProp] &&
      mergeDatachainItems(datachain[taxonomyProp], taxonomyApiData);

    res[taxonomyProp] = mergedDatachainItems;

    return res;
  }, {});
}

interface Props {
  taxonomyApiData: TaxonomyApiData[];
  taxonomyPropLabels: TaxonomyPropLabels;
  datachain?: {
    [name: string]: TaxonomyDetails[];
  };
}

export function prepareSensorTaxonomy({
  taxonomyApiData,
  taxonomyPropLabels,
  datachain,
}: Props) {
  if (!datachain) return {};

  return Object.values(taxonomyProps).reduce((res, taxonomyProp) => {
    const mergedDatachainItems =
      datachain[taxonomyProp] &&
      mergeDatachainItems(datachain[taxonomyProp], taxonomyApiData);

    if (mergedDatachainItems) {
      res[taxonomyProp] = {
        taxonomyProp,
        label: taxonomyPropLabels[taxonomyProp],
        options: mergedDatachainItems,
      };
    }

    return res;
  }, {});
}
