import React from 'react';

import { useTranslation } from 'react-i18next';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { SUPPORTED_LANGUAGES } from 'common/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 100,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    input: {
      padding: '10px 26px 10px 12px',
    }
  }),
);

export default function LocaleSwitcher() {  
  const classes = useStyles();

  const { i18n } = useTranslation();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    i18n.changeLanguage(event.target.value as string);
  };

  const locales = SUPPORTED_LANGUAGES;

  const getLanguage = () => window.localStorage.i18nextLng || i18n.language;

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="locale-switcher-label">Language</InputLabel>
      <Select
        labelId="locale-switcher-label"
        id="locale-switcher"
        value={getLanguage()}
        onChange={handleChange}
        label="Language"
      >
        {
          locales.map((language) => (
            <MenuItem key={language} value={language}>{language}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}
