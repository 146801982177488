import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Typography } from 'libs/mui';
import { feedbackQuestionTypes } from 'common/constants';
import FeedbackEmojis from './FeedbackEmojis';
import FeedbackComment from './FeedbackComment';
import FeedbackThanks from './FeedbackThanks';

interface Props {
  onClick: (name: string) => void;
  classes: any;
  text: string;
  type: string;
  progressTextAriaLabel: string;
  elementName: string;
  feedbackEmail: string;
  feedbackText: string;
}

function FeedbackQuestion({
  classes,
  onClick,
  text,
  type,
  progressTextAriaLabel,
  elementName,
  feedbackEmail,
  feedbackText
}: Props) {
  const [userIsGivingFeedback, setUserIsGivingFeedback] = useState(false);
  const { t } = useTranslation();

  const handleOnClick = (value: string) => {
    if (type === feedbackQuestionTypes.THANKS) {
      setUserIsGivingFeedback(false);
    } else {
      setUserIsGivingFeedback(true);
    }
    onClick(value);
  };

  return (
    <div className={classes.container}>
      {type === feedbackQuestionTypes.THANKS ? (
        <FeedbackThanks
          elementName={elementName}
          feedbackEmail={feedbackEmail}
          feedbackText={feedbackText}
        />
      ) : (
        <>
          <Typography variant="srOnly">
            {t('feedback.screenReaderSubtitle')}
          </Typography>
          <Typography className={classes.titleFeedback} aria-hidden>
            {t('feedback.title')}
          </Typography>
          <Typography className={classes.text} aria-hidden>
            {text}
          </Typography>
          <div className={classes.answerContainer}>
            <Typography
              variant="srOnly"
              aria-live={userIsGivingFeedback ? 'polite' : 'off'}
            >
              {`${progressTextAriaLabel} ${text}`}
            </Typography>
            {type === feedbackQuestionTypes.EMOJI && (
              <FeedbackEmojis onClick={handleOnClick} />
            )}
            {type === feedbackQuestionTypes.COMMENT && (
              <FeedbackComment onClick={handleOnClick} />
            )}
          </div>
        </>
      )}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    titleFeedback: {
      fontWeight: 800,
      ...theme.custom.fonts.primary.m,
      color: theme.custom.sensor.feedback.header,
    },
    text: {
      margin: theme.spacing(2),
      marginLeft: 0,
      marginRight: 0,
      minHeight: '50px',
      color: theme.custom.sensor.feedback.paragraph,
      ...theme.custom.fonts.secondary.m,
    },
    answerContainer: {
      minHeight: '50px',
    },
  });

export default withStyles(styles)(FeedbackQuestion);
