import { InvalidEvent } from 'react';

export function getSensorPath(sensorId: string) {
  return `/sensors/${sensorId}`;
}
export function getPagePath(pageId: string) {
  return `/pages/${pageId}`;
}

export function showPlaceholderOnImgError(pageholderSrc: string) {
  return (e: InvalidEvent<HTMLImageElement>) => {
    e.target.src = pageholderSrc;
  };
}

export function getIconPlaceholder(icon: string) {
  return `/images/${icon}.svg`;
}
