import useReducerState from 'hooks/useReducerState';
import React, { ReactNode, useCallback } from 'react';

import customerReducer, {
  fetchCustomerRequested,
  fetchCustomerSuccessed,
  fetchCustomerFailed,
  customerInitialState,
  CustomerStateType,
} from 'reducers/customer';
import { getCustomer } from 'sideEffects/api';

export const CustomerContext = React.createContext([customerInitialState, {}] as [
  CustomerStateType,
  { [name: string]: any }
]);

export function CustomerProvider({ children }: { children: ReactNode }) {
  const [customer, customerActions] = useReducerState(
    customerReducer,
    customerInitialState,
    fetchCustomerRequested,
    fetchCustomerSuccessed,
    fetchCustomerFailed,
  );

  const onRequest = useCallback(
    () => {
      if (customer.data) return;

      getCustomer(customerActions.onSuccess, customerActions.onError)
    },
    []
  );

  const value: [CustomerStateType, { onRequest; onSuccess; onError }] = [
    customer as CustomerStateType,
    { ...customerActions, onRequest },
  ];

  return (
    <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>
  );
}
