import { PageData, ReducerStateType } from '../common/types';

export const FETCH_PLACE_REQUESTED = 'FETCH_PLACE_REQUESTED';
export function fetchPageRequested(): PageActionType {
  return { type: FETCH_PLACE_REQUESTED };
}

export const FETCH_PLACE_SUCCESSED = 'FETCH_PLACE_SUCCESSED';
export function fetchPageSuccessed(page): PageActionType {
  return { type: FETCH_PLACE_SUCCESSED, payload: { page } };
}

export const FETCH_PLACE_FAILED = 'FETCH_PLACE_FAILED';
export function fetchPageFailed(error): PageActionType {
  return { type: FETCH_PLACE_FAILED, payload: { error } };
}

export interface PageStateType extends ReducerStateType {
  id?: string;
  data?: PageData;
}

export const pageInitialState: PageStateType = {
  isFetching: false,
  didInvalidate: false,
  data: null,
  error: null,
};

interface PagePayloadType {
  id?: string;
  page?: PageData;
  error?: Error;
}

interface PageActionType {
  type: string;
  payload?: PagePayloadType;
}

function pageReducer(state, { type, payload }: PageActionType) {
  switch (type) {
    case FETCH_PLACE_REQUESTED:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
        error: false,
      };
    case FETCH_PLACE_SUCCESSED:
      return {
        ...state,
        isFetching: false,
        id: payload.id,
        data: payload.page,
      };
    case FETCH_PLACE_FAILED:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload.error,
        didInvalidate: true,
      };
    default:
      throw new Error();
  }
}

export default pageReducer;
