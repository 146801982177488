import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import Pages from 'containers/Pages';
import Sensors from 'containers/Sensors';
import Redirect from 'components/Redirect';

import { PageProvider } from 'context/page';
import Localization from 'locale/translations/Localization';
import theme from 'styles/theme';
import { CustomerProvider } from 'context/customer';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <PageProvider>
        <CustomerProvider>
          <Router>
            <Localization />
            <Routes>
              {/* TODO: Systems and Sensor routes currently for backward compatibility of links. Should remove after transition period. */}
              <Route path="sensors/*" element={<Sensors />} />
              <Route path="systems/*" element={<Sensors />} /> 
              {/*  */}

              <Route path="technologies/*" element={<Sensors />} />
              <Route path="places/*" element={<Pages />} />
              <Route path="pages/*" element={<Pages />} />
              <Route path="*" element={<Redirect />} />
            </Routes>
          </Router>
          <ReactQueryDevtools />
        </CustomerProvider>
        </PageProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
