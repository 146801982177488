import showdown from 'showdown';

const paragraphTagFilter = {
  type: 'output',
  filter(text: string) {
    const re = /<\/?p[^>]*>/gi;
    return text.replace(re, '');
  },
};

// @ts-ignore
export const markdownConverter = new showdown.Converter({
  simplifiedAutoLink: true,
  simpleLineBreaks: true,
  openLinksInNewWindow: true,
  emoji: true,
  extensions: [paragraphTagFilter],
});

export default showdown;
