import React from 'react';
import { FAQ } from 'common/types';
import { trackFaqAccordion } from 'libs/analytics';
import { markdownConverter } from 'libs/showdown';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ExpandMoreIcon,
} from 'libs/mui';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

interface Props {
  title: FAQ['question'];
  children: FAQ['response'];
  classes: any;
  elementId: string;
  elementName: string;
  elementType: string;
}

function SensorFAQAccordion({
  classes,
  title,
  children: response,
  elementId,
  elementName,
  elementType
}: Props) {
  const parsedFaqResponse = markdownConverter.makeHtml(response);
  return (
    <Accordion
      className={classes.root}
      onChange={() => {
        trackFaqAccordion({ title, elementId, elementName, elementType });
      }}
    >
      <AccordionSummary
        className={classes.summaryRoot}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={classes.summaryText}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.detailsRoot}>
        <Typography
          className={classes.detailsText}
          dangerouslySetInnerHTML={{ __html: parsedFaqResponse }}
        />
      </AccordionDetails>
    </Accordion>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      border: 'none',
      boxShadow: 'none',
      padding: theme.spacing(),
      paddingBottom: theme.spacing(2),
      '&:before': {
        content: 'none',
      },
    },
    summaryRoot: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    summaryText: {
      fontWeight: 500,
      color: theme.custom.sensor.faq.question,
      ...theme.custom.fonts.secondary.m,
    },
    detailsRoot: {
      padding: 0,
    },
    detailsText: {
      color: theme.custom.sensor.faq.response,
      ...theme.custom.fonts.secondary.s,
    },
  });

export default withStyles(styles)(SensorFAQAccordion);
