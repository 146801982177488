import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Redirect from 'components/Redirect';
import Sensor from './Sensor';
import Embed from './Embed';

function Sensors() {
  return (
    <Routes>
      <Route path="/:sensorId" element={<Sensor />} />
      <Route path="/:sensorId/embed" element={<Embed />} />
      <Route path="*" element={<Redirect />} />
    </Routes>
  );
}

export default Sensors;
