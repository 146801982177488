const bostonTheme = {
  link: '#45789C',
  tab: '#091F2F',
  header: '#091F2F',
  sensor: {
    badge: '#4C4C4C',
    paragraph: '#58585B',
    taxonomy: {
      value: '#58585B',
      prop: '#45789C',
      details: '#58585B',
    },
    feedback: {
      progressBar: '#091F2F',
      header: '#4C4C4C',
      paragraph: '#58585B',
      background: '#FFFFFF',
      divider: '#BFBFBF',
      counter: '#4C4C4C',
    },
    page: {
      backgroundColor: '#091F2F',
      paragraph: 'white',
      divider: '#FB4D42',
      link: '#FB4D42',
    },
    faq: {
      question: '#45789C',
      response: '#58585B',
    },
  },
  page: {
    address: '#666666',
    taxonomy: {
      title: '#4C4C4C',
      description: '#58585B',
      border: '#E0E0E0',
    },
    sort: {
      header: '#58585B',
      selected: '#45789C',
      option: '#58585B',
      button: '#58585B',
    },
  },
  footer: {
    paragraph: '#515151',
  },
  fonts: {
    primary: {
      l: {
        fontSize: '1.25rem',
        fontFamily: '"Montserrat", sans-serif',
        letterSpacing: '0.15px',
        lineHeight: '1.5rem',
      },
      m: {
        fontSize: '1rem',
        fontFamily: '"Montserrat", sans-serif',
        letterSpacing: '0.15px',
        lineHeight: '1.5rem',
      },
      s: {
        fontSize: '0.875rem',
        fontFamily: '"Montserrat", sans-serif',
        letterSpacing: '0.15px',
        lineHeight: '1.25rem',
      },
      xs: {
        fontSize: '0.75rem',
        fontFamily: '"Montserrat", sans-serif',
        letterSpacing: '0.15px',
        lineHeight: '1.25rem',
      },
    },
    secondary: {
      m: {
        fontSize: '1rem',
        fontFamily: '"Lora", serif',
        letterSpacing: '0.15px',
        lineHeight: '1.5rem',
      },
      s: {
        fontSize: '0.875rem',
        fontFamily: '"Lora", serif',
        letterSpacing: '0.15px',
        lineHeight: '1.25rem',
      },
      xs: {
        fontSize: '0.75rem',
        fontFamily: '"Lora", serif',
        letterSpacing: '0.15px',
        lineHeight: '1.25rem',
      },
    },
  },
};

export default bostonTheme;
